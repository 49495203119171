<script>
import {useLiveStreamStore} from "@/stores/livestream";
import helpers from "../../helpers/helpers";

export default {
  name: "RecentLiveStreamsList",
  computed: {
    helpers() {
      return helpers;
    }
  },
  methods: {
    useLiveStreamStore,
    getFormattedDate(isoString) {
      const date = new Date(isoString);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      };
      return date.toLocaleDateString('en-GB', options);
    },
    getFormattedDateTime(isoString) {
      const date = new Date(isoString);
      return this.getFormattedDate(isoString) + ', ' + date.toLocaleTimeString('de-DE', {timeZone: 'UTC'});
    },
    getFormattedDuration(duration) {
      return (Math.round(duration / 60 * 100) / 100) + ' min';
    },
    playVideo(flight, key, index) {
      useLiveStreamStore().activeVideo = {
        // eslint-disable-next-line no-undef
        videoTitle: this.getFormattedDateTime(flight.date),
        videoDescription: flight.description,
        videoDetails: flight.details,
        videoDuration: flight.duration,
        videoUrl: flight.url,
        videoLocation: flight.location,
      };
      useLiveStreamStore().videoDate = key;
      useLiveStreamStore().videoIndex = index;
      useLiveStreamStore().isLivestreamVideoActive = true;
    },
  },
  data() {
    return {};
  }
};
</script>

<template>
  <div>
    <h2 class="text-h4 mt-2">Recent Livestreams</h2>
    <div id="scroll-container">
      <div v-for="(flights, date) in useLiveStreamStore().recentFlights" :key="date" class="mt-3">
        <h3 class="text-h5 font-weight-medium mb-2">{{ getFormattedDate(date) }}</h3>
        <v-row v-for="(flight, index) in flights" :key="index" class="video-item"
               @click="playVideo(flight, date, index)">
          <v-col xs="12" sm="6" >
            <div class="d-flex rounded-lg video-card">
              <img class="rounded-lg" :src="require('@/assets/video_preview/' + flight.previewImg)"
                   alt="video preview"/>
              <span :style="useLiveStreamStore().isActiveVideo(date, index) ? 'background-color: #1976d2' : ''"
                    class="ml-1 mt-1 mb-auto pa-1 rounded-lg video-badge">{{
                  getFormattedDuration(flight.duration)
                }}</span>
            </div>
          </v-col>
          <v-col xs="12" sm="6">
            <div class="text-h6" :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''">
              {{ getFormattedDateTime(flight.date) }}
            </div>
            <div :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''">{{
                flight.description
              }}
            </div>
            <div class="mt-2" :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''">
              <v-icon :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''">
                mdi-map-marker-outline
              </v-icon>
              Aldrans
            </div>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media only screen and (min-width: 1270px) {
  #scroll-container {
    height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.video-card {
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
}

.video-badge {
  color: white;
  background-color: #373737;
  position: absolute;
}

.video-item:hover {
  cursor: pointer;
}
</style>
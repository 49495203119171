<script>
import OrderSatCom from './OrderSatCom.vue';

export default {
  name: "OrderSatComIndex",
  components: {
    OrderSatCom
  },
}
</script>

<template>
  <div>
    <OrderSatCom/>
  </div>
</template>

<style scoped>

</style>
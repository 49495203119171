<script>
import {useLiveStreamStore} from "@/stores/livestream";

export default {
  name: "LiveStreamOverview",
  methods: {
    useLiveStreamStore,
    playLiveStream(id) {
      useLiveStreamStore().activeVideo = {
        // eslint-disable-next-line no-undef
        videoTitle: id === 1 ? 'Livestream 1' : 'Livestream 2',
        videoDescription: '',
        videoDetails: {
          bitrate: '2000Kbps',
          resolution: '480p',
          fps: '30FPS',
        },
        videoLocation: [11.435620, 47.262560],
        videoDuration: -1,
        videoUrl: id === 1 ? 'https://dronenstream.geoville.com/live/livestream.flv' : 'https://dronenstream.geoville.com/live/livestream.flv'
      };
      useLiveStreamStore().videoDate = '';
      useLiveStreamStore().videoIndex = -1;
      useLiveStreamStore().isLivestreamVideoActive = true;
    },
    playVideo(flight, key, index) {
      useLiveStreamStore().activeVideo = {
        // eslint-disable-next-line no-undef
        videoTitle: this.getFormattedDateTime(flight.date),
        videoDescription: flight.description,
        videoDetails: flight.details,
        videoDuration: flight.duration,
        videoUrl: flight.url,
        videoLocation: flight.location,
      };
      useLiveStreamStore().videoDate = key;
      useLiveStreamStore().videoIndex = index;
      useLiveStreamStore().isLivestreamVideoActive = true;
    },
    getFormattedDate(isoString) {
      const date = new Date(isoString);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      };
      return date.toLocaleDateString('en-GB', options);
    },
    getFormattedDateTime(isoString) {
      const date = new Date(isoString);
      return this.getFormattedDate(isoString) + ', ' + date.toLocaleTimeString('de-DE', {timeZone: 'UTC'});
    },
    getFormattedDuration(duration) {
      return (Math.round(duration / 60 * 100) / 100) + ' min';
    },
  }
}
</script>

<template>
  <v-row>
    <v-col>
      <div class="heading-container justify-space-between">
        <h2 class="text-h4 mb-5">Active Livestreams</h2>
        <v-btn @click="playLiveStream(1)" color="primary" class="rounded-lg">Start new Livestream</v-btn>
      </div>
      <v-row>
        <v-col xs="12" sm="12" md="6" lg="3">
          <div class="video-item" @click="playLiveStream(1)">
            <div class="d-flex rounded-lg video-card">
              <img class="rounded-lg livestream-img-preview" :src="require('@/assets/livestream_preview.png')"
                   alt="livestream preview"/>
              <span class="ml-1 mt-1 mb-auto pa-1 rounded-lg livestream-badge">Live</span>
            </div>
            <div class="text-h6">Livestream 1</div>
            <div>
              <v-icon>mdi-map-marker-outline</v-icon>
              Aldrans
            </div>
          </div>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="3">
          <div class="video-item" @click="playLiveStream(2)">
            <div class="d-flex rounded-lg video-card">
              <img class="rounded-lg livestream-img-preview" :src="require('@/assets/livestream_preview.png')"
                   alt="livestream preview"/>
              <span class="ml-1 mt-1 mb-auto pa-1 rounded-lg livestream-badge">Live</span>
            </div>
            <div class="text-h6">Livestream 2</div>
            <div>
              <v-icon>mdi-map-marker-outline</v-icon>
              Aldrans
            </div>
          </div>
        </v-col>
      </v-row>
      <h2 class="mt-10 text-h4">Recent Livestreams</h2>
      <div v-for="(flights, date) in useLiveStreamStore().recentFlights" :key="date">
        <div class="text-h5 mt-5 mb-2">{{ getFormattedDate(date) }}</div>
        <v-row>
          <v-col v-for="(flight, index) in flights" :key="index" xs="12" sm="12" md="6" lg="3">
            <div class="video-item" @click="playVideo(flight, date, index)">
              <div class="d-flex rounded-lg video-card">
                <img class="rounded-lg" :src="require('@/assets/video_preview/' + flight.previewImg)"
                     alt="video preview"/>
                <span
                    class="ml-1 mt-1 mb-auto pa-1 rounded-lg video-badge">{{
                    getFormattedDuration(flight.duration)
                  }}</span>
              </div>
              <div class="text-h6">{{ getFormattedDateTime(flight.date) }}</div>
              <div>
                <v-icon>mdi-map-marker-outline</v-icon>
                Aldrans
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.video-card {
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
}

.livestream-badge {
  color: white;
  background-color: #ff0000;
  position: absolute;
}

.video-badge {
  color: white;
  background-color: #373737;
  position: absolute;
}

.video-item:hover {
  cursor: pointer;
}

.livestream-img-preview {
  filter: blur(3px);
  margin: -1px;
  padding: 1px;
}

@media only screen and (min-width: 600px) {
  .heading-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
  }
}

.heading-container {
  margin-bottom: 20px;
}
</style>
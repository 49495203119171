<script>
import PlayLiveStreamOrVideo from "@/views/livestream/PlayLiveStreamOrVideo.vue";
import {useLiveStreamStore} from "@/stores/livestream";
import LiveStreamOverview from "@/views/livestream/LiveStreamOverview.vue";

export default {
  name: "LiveStreamIndex",
  methods: {
    useLiveStreamStore,
  },
  components: {LiveStreamOverview, PlayLiveStreamOrVideo},
};
</script>

<template>
  <v-card style="height: fit-content" class="rounded-lg">
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col>
            <div class="text-h3">Livestream</div>
          </v-col>
        </v-row>
        <LiveStreamOverview v-if="!useLiveStreamStore().isLivestreamVideoActive"/>
        <PlayLiveStreamOrVideo v-else/>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
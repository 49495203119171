<script>
import {useLiveStreamStore} from "@/stores/livestream";
import Map from 'ol/Map';
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {transform} from "ol/proj";
import {Icon, Style} from "ol/style";
import {mapState} from "pinia";
import mpegts from 'mpegts.js';
import RecentLiveStreamsList from "@/views/livestream/RecentLiveStreamsList.vue";

export default {
  name: "PlayLiveStreamOrVideo",
  components: {RecentLiveStreamsList},
  data() {
    return {
      showHEVCNotSupportedInfo: false,
      isHEVCSupported: false,
      liveStreamMap: null,
      view: new View({
        center: transform(useLiveStreamStore().activeVideo.videoLocation, 'EPSG:4326', 'EPSG:3857'),
        zoom: 14
      }),
      clientPointLayer: new VectorLayer({
        className: 'clientPoint',
        source: new VectorSource({
          features: [new Feature(new Point(transform(useLiveStreamStore().activeVideo.videoLocation, 'EPSG:4326', 'EPSG:3857')))],
        }),
        style: new Style({
          image: new Icon({
            color: '#f44336',
            crossOrigin: 'anonymous',
            src: 'marker.svg',
            height: 30,
            anchor: [0.5, 1]
          })
        }),
        visible: true,
        zIndex: 100000
      })
    };
  },
  computed: {
    ...mapState(useLiveStreamStore, ['videoIndex'])
  },
  watch: {
    videoIndex() {
      this.updateMap();
    }
  },
  methods: {
    useLiveStreamStore,
    getFormattedDuration(duration) {
      return (Math.round(duration / 60 * 100) / 100) + ' min';
    },
    initLiveStreamMap() {
      this.liveStreamMap = new Map({
        target: 'liveStreamMap',
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          this.clientPointLayer
        ],
        view: this.view
      });
    },
    updateMap() {
      this.liveStreamMap.getLayers().forEach(layer => {
        if (layer.getClassName() === 'clientPoint') {
          layer.getSource().getFeatures()[0].getGeometry().setCoordinates(transform(useLiveStreamStore().activeVideo.videoLocation, 'EPSG:4326', 'EPSG:3857'));
        }
      });
      this.liveStreamMap.getView().setCenter(transform(useLiveStreamStore().activeVideo.videoLocation, 'EPSG:4326', 'EPSG:3857'));
    },
    resetLivestream() {
      useLiveStreamStore().isLivestreamVideoActive = false;
      useLiveStreamStore().activeVideo = {};
      useLiveStreamStore().videoIndex = -1;
      useLiveStreamStore().videoDate = '';
    }
  },
  mounted() {
    this.initLiveStreamMap();

    const video = document.createElement('video');
    this.isHEVCSupported = video.canPlayType('video/mp4; codecs="hev1.1.6.L93.B0"') || video.canPlayType('video/mp4; codecs="hvc1.1.6.L93.B0"');
    this.showHEVCNotSupportedInfo = !this.isHEVCSupported;

    if (useLiveStreamStore().isLiveStream && this.isHEVCSupported) {
      // eslint-disable-next-line no-undef
      if (mpegts.getFeatureList().mseLivePlayback) {
        const videoElement = document.getElementById('liveStreamPlayer');
        // eslint-disable-next-line no-undef
        const player = mpegts.createPlayer({
          type: 'flv',  // could also be mpegts, m2ts, flv
          isLive: true,
          url: 'https://dronenstream.geoville.com/live/livestream.flv'
        });
        player.attachMediaElement(videoElement);
        player.load();
      }
    }
  }
};
</script>

<template>
  <v-row>
    <v-dialog v-model="showHEVCNotSupportedInfo" width="500">
      <v-card>
        <v-card-title>Codec not supported</v-card-title>
        <v-card-text>Your browser does not support the livestream codec. Make sure to use a Chromium based browser or
          Safari on one of the following platforms:
          <ul>
            <li>Windows</li>
            <li>MacOS</li>
            <li>IOS/iPadOS</li>
            <li>Android</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showHEVCNotSupportedInfo = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col xs="12" sm="12" md="12" lg="7">
      <div style="width: 100%;">
        <video v-if="useLiveStreamStore().isLiveStream" id="liveStreamPlayer" style="width: inherit" class="rounded-lg"
               controls autoplay></video>
        <video :key="useLiveStreamStore().videoIndex" v-else style="width: inherit" class="rounded-lg" autoplay controls
               preload="auto">
          <source :src="useLiveStreamStore().activeVideo.videoUrl" type="video/mp4">
        </video>
        <v-row class="mt-2">
          <v-col xs="12" sm="12" md="6" lg="6">
            <div class="text-h4">{{ useLiveStreamStore().activeVideo.videoTitle }}</div>
            <div v-if="useLiveStreamStore().activeVideo.videoDuration>0" class="text-subtitle-1 mt-2">
              {{ useLiveStreamStore().activeVideo.videoDescription }}
            </div>
            <template v-if="useLiveStreamStore().activeVideo.videoDuration>0">
              <div class="text-h6 mt-3">Details</div>
              <div>
                <div v-if="useLiveStreamStore().activeVideo.videoDuration>0" class="d-flex flex-row">
                  <div class="detailsKey">Duration</div>
                  <div>{{ this.getFormattedDuration(useLiveStreamStore().activeVideo.videoDuration) }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="detailsKey">Resolution</div>
                  <div>{{ useLiveStreamStore().activeVideo.videoDetails.resolution }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="detailsKey">Bitrate</div>
                  <div>{{ useLiveStreamStore().activeVideo.videoDetails.bitrate }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="detailsKey">FPS</div>
                  <div>{{ useLiveStreamStore().activeVideo.videoDetails.fps }}</div>
                </div>
              </div>
            </template>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6">
            <div id="liveStreamMap" class="rounded-lg"></div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col xs="12" sm="12" md="12" lg="5">
      <v-btn style="width: 100%" v-if="useLiveStreamStore().isLivestreamVideoActive"
             @click="resetLivestream">Back to Livestream overview
      </v-btn>
      <RecentLiveStreamsList/>
    </v-col>
  </v-row>
</template>

<style scoped>
.detailsKey {
  width: 100px;
}

#liveStreamMap {
  width: auto;
  height: 22vh;
  overflow: hidden;
}
</style>
import {defineStore} from "pinia";

export const useLiveStreamStore = defineStore('liveStreamStore', {
    state: () => {
        return {
            recentFlights: {
                "2024-10-29": [{
                    date: "2024-10-29T14:48:00.000Z",
                    description: "This is a recent livestream from 2024-10-29.",
                    duration: 224,
                    url: 'https://staticfiles.geoville.com/data/connexstream/vid_01.mp4',
                    previewImg: '01.png',
                    location: [11.45059, 47.25587],
                    details: {
                        bitrate: '2000Kbps',
                        resolution: '1080p',
                        fps: '60FPS',
                    }
                }, {
                    date: "2024-10-29T12:48:00.000Z",
                    description: "This is a recent livestream from 2024-10-29.",
                    duration: 183,
                    url: 'https://staticfiles.geoville.com/data/connexstream/vid_02.mp4',
                    previewImg: '02.png',
                    location: [11.45059, 47.25587],
                    details: {
                        bitrate: '2000Kbps',
                        resolution: '1080p',
                        fps: '60FPS',
                    }
                }, {
                    date: "2024-10-29T04:48:00.000Z",
                    description: "This is a recent livestream from 2024-10-29.",
                    duration: 287,
                    url: 'https://staticfiles.geoville.com/data/connexstream/vid_03.mp4',
                    previewImg: '03.png',
                    location: [11.45059, 47.25587],
                    details: {
                        bitrate: '2000Kbps',
                        resolution: '1080p',
                        fps: '60FPS',
                    }
                }],
                "2024-10-26": [{
                    date: "2024-10-26T12:48:00.000Z",
                    description: "This is a recent livestream from 2024-10-26.",
                    duration: 1005,
                    url: 'https://staticfiles.geoville.com/data/connexstream/vid_04.mp4',
                    previewImg: '04.png',
                    location: [11.45059, 47.25587],
                    details: {
                        bitrate: '2000Kbps',
                        resolution: '1080p',
                        fps: '60FPS',
                    }
                }, {
                    date: "2024-10-26T11:48:00.000Z",
                    description: "This is a recent livestream from 2024-10-26.",
                    duration: 50,
                    url: 'https://staticfiles.geoville.com/data/connexstream/vid_05.mp4',
                    previewImg: '05.png',
                    location: [11.45059, 47.25587],
                    details: {
                        bitrate: '2000Kbps',
                        resolution: '1080p',
                        fps: '60FPS',
                    }
                }],
            },
            videoDate: '',
            videoIndex: -1,
            activeVideo: {
                videoTitle: '',
                videoDescription: '',
                videoDetails: {
                    bitrate: '2000Kbps',
                    resolution: '1080p',
                    fps: '60FPS',
                },
                videoLocation: [0, 0],
                videoDuration: -1,
                videoUrl: ''
            },
            isLivestreamVideoActive: false,
        };
    },
    getters: {
        getActiveVideo: (state) => {
            console.log("Hello World");
            return state.videoIndex;
        },
        isActiveVideo: (state) => {
            return (date, index) => state.videoDate === date && state.videoIndex === index;
        },
        isLiveStream: (state) => {
            return state.videoDate.length < 1 && state.videoIndex < 0;
        }
    },
});
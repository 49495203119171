<script>
export default {
  name: "OrderSatCom"
}
</script>

<template>
  <v-card elevation="0" class="rounded-lg">
    <v-card-title>Order SatCom</v-card-title>
  </v-card>
</template>

<style scoped>

</style>